<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <p>Сегодня будет несложное занятие на 30 минут работы )</p>
            <h3 class="nomargin">Необходимые материалы:</h3>           
            <ul>
                <li>Чертеж в 3-ех экземплярах</li>
                <li>Карандаш</li>
                <li>Малярный скотч</li>
                <li>Ножницы или макетный нож</li>
                <li>Картон А4 или А5</li>
            </ul>
            <h3>1. Соединяем чертеж</h3>
        </div>

        <VideoView video-id="b2fedc516e0e475981146ab1e07ac5c2"/>

        <div class="MBtextcont">
            <h3>2. Вырезаем трафареты</h3>
        </div>

        <VideoView video-id="9c5b50083d1d401ca88e02a8a116bb0f"/>


        <div class="MBtextcont">
            <h3 class="nomargin">Что нужно сделать:</h3>
            <ol>
                <li><b>Распечатать <a target="_blank" href="/files/DrawingBEST.pdf">чертеж</a></b> на листе А4 в 3 экземплярах</li>
                <li>Один экземпляр <b>чертежа соединить</b></li>
                <li>Второй и третий экземпляр пустить на <b>трафареты:</b></li>
                <ol type="a">
                    <li>Приклеить все части тела (вид сбоку и спереди) к картонке</li>
                    <li>Обозначить центр (где будет проходить шпажка)</li>
                    <li>Вырезать на 5мм меньше голову, верхнюю и нижнюю часть тела (внутреннюю часть трафарета тела сохраните)</li>
                    <li>Вырезать по контурам ногу и руку</li>
                </ol>
                <li><b>Подготовить материалы</b> к завтрашнему занятию: пластилин, нож для пластилина (любой тупой ненужный), шпажки, линейка, карандаш, фольга, молоток (опционально), металлический гранулят</li>
            </ol>
           <h3><a href="/mycourses/homework">Как сдавать ДЗ куратору / в чат?</a></h3>
           <h3>Время выполнения: 30мин</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js'

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: chapters
        }
        }
    }
</script>

<style>

</style>